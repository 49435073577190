import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AdminRoutes from "./routes/AdminRoutes";
import PocetnaPage from "./pages/administrator/PocetnaPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import RegioniPage from "./pages/administrator/prikazi/RegioniPage";
import PogoniPage from "./pages/administrator/prikazi/PogoniPage";
import LinijePage from "./pages/administrator/prikazi/LinijePage";
import RadniCentriPage from "./pages/administrator/prikazi/RadniCentriPage";
import KorisniciPage from "./pages/administrator/prikazi/KorisniciPage";
import SkladistaPage from "./pages/administrator/prikazi/SkladistaPage";
import LokacijePage from "./pages/administrator/prikazi/LokacijePage";
import SifrarniciPage from "./pages/administrator/prikazi/SifrarniciPage";
import SastavnicePage from "./pages/administrator/prikazi/SastavnicePage";
import TransportniMedijumi from "./pages/administrator/prikazi/TransportniMedijumi";
import MasinePage from "./pages/administrator/prikazi/MasinePage";
import RegionInfoPage from "./pages/administrator/informacije/RegionInfoPage";
import PogonInfoPage from "./pages/administrator/informacije/PogonInfoPage";
import LinijaInfoPage from "./pages/administrator/informacije/LinijaInfoPage";
import RadniCentarInfoPage from "./pages/administrator/informacije/RadniCentarInfoPage";
import TipoviRadnogCentraPage from "./pages/administrator/prikazi/TipoviRadnogCentraPage";
import TipRadnogCentraInfoPage from "./pages/administrator/informacije/TipRadnogCentraInfoPage";
import KorisnikInfoPage from "./pages/administrator/informacije/KorisnikInfoPage";
import TipoviSkladistaPage from "./pages/administrator/prikazi/TipoviSkladistaPage";
import TipSkladistaInfoPage from "./pages/administrator/informacije/TipSkladistaInfoPage";
import LokacijaInfoPage from "./pages/administrator/informacije/LokacijaInfoPage";
import SkladisteInfoPage from "./pages/administrator/informacije/SkladisteInfoPage";
import RepromaterijaliPage from "./pages/administrator/prikazi/RepromaterijaliPage";
import ProfilPage from "./pages/administrator/ProfilPage";
import TransportniMedijumInfoPage from "./pages/administrator/informacije/TransportniMedijumInfoPage";
import MasinaInfoPage from "./pages/administrator/informacije/MasinaInfoPage";
import RepromaterijalInfoPage from "./pages/administrator/informacije/RepromaterijalInfoPage";

const ApplicationRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" index exact element={<LoginPage />} />
        <Route path="/*" exact element={<ProtectedRoute />}>
          <Route path="administrator/*" exact element={<AdminRoutes />}>
            <Route path="pocetna" exact element={<PocetnaPage />} />

            <Route path="profil/:id" exact element={<ProfilPage />} />

            <Route path="regioni" exact element={<RegioniPage />} />
            <Route path="region/:id" exact element={<RegionInfoPage />} />

            <Route path="pogoni" exact element={<PogoniPage />} />
            <Route path="pogon/:id" exact element={<PogonInfoPage />} />

            <Route path="linije" exact element={<LinijePage />} />
            <Route path="linija/:id" exact element={<LinijaInfoPage />} />

            <Route
              path="tipovi-radnog-centra"
              exact
              element={<TipoviRadnogCentraPage />}
            />
            <Route
              path="tip-radnog-centra/:id"
              exact
              element={<TipRadnogCentraInfoPage />}
            />

            <Route path="radni-centri" exact element={<RadniCentriPage />} />
            <Route
              path="radni-centar/:id"
              exact
              element={<RadniCentarInfoPage />}
            />

            <Route path="korisnici" exact element={<KorisniciPage />} />
            <Route path="korisnik/:id" exact element={<KorisnikInfoPage />} />

            <Route
              path="tipovi-skladista"
              exact
              element={<TipoviSkladistaPage />}
            />
            <Route
              path="tip-skladista/:id"
              exact
              element={<TipSkladistaInfoPage />}
            />

            <Route path="skladista" exact element={<SkladistaPage />} />
            <Route path="skladiste/:id" exact element={<SkladisteInfoPage />} />

            <Route path="lokacije" exact element={<LokacijePage />} />
            <Route path="lokacija/:id" exact element={<LokacijaInfoPage />} />

            <Route path="sifranici" exact element={<SifrarniciPage />} />
            <Route path="sastavnice" exact element={<SastavnicePage />} />

            <Route
              path="transportni-medijumi"
              exact
              element={<TransportniMedijumi />}
            />
            <Route
              path="transportni-medijum/:id"
              exact
              element={<TransportniMedijumInfoPage />}
            />

            <Route path="masine" exact element={<MasinePage />} />
            <Route path="masina/:id" exact element={<MasinaInfoPage />} />

            <Route
              path="repromaterijali"
              exact
              element={<RepromaterijaliPage />}
            />
            <Route
              path="repromaterijal/:id"
              exact
              element={<RepromaterijalInfoPage />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ApplicationRoutes></ApplicationRoutes>
    </QueryClientProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
