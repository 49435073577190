import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SouthAmericaTwoToneIcon from "@mui/icons-material/SouthAmericaTwoTone";
import FactoryTwoToneIcon from "@mui/icons-material/FactoryTwoTone";
import PrecisionManufacturingTwoToneIcon from "@mui/icons-material/PrecisionManufacturingTwoTone";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import HandymanTwoToneIcon from "@mui/icons-material/HandymanTwoTone";
import { useNavigate } from "react-router";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import FormatColorFillTwoToneIcon from "@mui/icons-material/FormatColorFillTwoTone";

const mainListItems = [
  {
    text: "Početna",
    icon: <HomeRoundedIcon />,
    route: "../administrator/pocetna",
  },
  {
    text: "Regioni",
    icon: <SouthAmericaTwoToneIcon />,
    route: "../administrator/regioni",
  },
  {
    text: "Pogoni",
    icon: <FactoryTwoToneIcon />,
    route: "../administrator/pogoni",
  },
  {
    text: "Linije",
    icon: <PrecisionManufacturingTwoToneIcon />,
    route: "../administrator/linije",
  },
  {
    text: "Tipovi radnog centra",
    icon: <MergeTypeIcon />,
    route: "../administrator/tipovi-radnog-centra",
  },
  {
    text: "Radni centri",
    icon: <BusinessCenterTwoToneIcon />,
    route: "../administrator/radni-centri",
  },
  {
    text: "Korisnici",
    icon: <PeopleAltTwoToneIcon />,
    route: "../administrator/korisnici",
  },
  {
    text: "Tipovi skladišta",
    icon: <AddToDriveIcon />,
    route: "../administrator/tipovi-skladista",
  },
  {
    text: "Skladišta",
    icon: <WarehouseTwoToneIcon />,
    route: "../administrator/skladista",
  },
  {
    text: "Lokacije",
    icon: <LocationOnTwoToneIcon />,
    route: "../administrator/lokacije",
  },
  {
    text: "Šifrarnici",
    icon: <VpnKeyTwoToneIcon />,
    route: "../administrator/sifrarnici",
  },
  {
    text: "Sastavnice",
    icon: <ArticleTwoToneIcon />,
    route: "../administrator/sastavnice",
  },
  {
    text: "Transportni medijumi",
    icon: <ArchiveTwoToneIcon />,
    route: "../administrator/transportni-medijumi",
  },
  {
    text: "Mašine",
    icon: <HandymanTwoToneIcon />,
    route: "../administrator/masine",
  },
  {
    text: "Repromaterijali",
    icon: <FormatColorFillTwoToneIcon />,
    route: "../administrator/repromaterijali",
  },
];

export default function MenuContent() {
  let selectedIndex = localStorage.getItem("index");

  const navigate = useNavigate();

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                localStorage.setItem("index", index);
                navigate(item.route);
              }}
              selected={index == selectedIndex}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
