import React, { useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getLokacija } from "../../../apis/administrator/getApi";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import BarkodLokacija from "../../../components/BarkodLokacija";

const LokacijaInfoPage = () => {
  let { id } = useParams();

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["lokacijaInfo", id],
    queryFn: () => getLokacija(id),
  });

  const printDiv = (divId) => {
    const container = document.getElementById(divId);
    const width = "100%";
    const height = "100%";
    const printWindow = window.open(
      "",
      "PrintMap",
      "width=" + width + ",height=" + height,
    );
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <PrivacyTipTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o lokaciji
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv lokacije: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.naziv}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Barkod: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
                id="stampanje"
              >
                <BarkodLokacija
                  naziv={data?.data.naziv}
                  barkod={data?.data.barkod}
                />
              </div>
            )}
            <Button
              sx={{ mt: 2, mb: 2 }}
              endIcon={<LocalPrintshopIcon />}
              variant="contained"
              onClick={() => printDiv("stampanje")}
            >
              Štampajte barkod
            </Button>
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            X; Y; Z: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.xyz}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Nosivost: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>
                {data?.data.nosivost} {data?.data.merna_jedinica_nosivost}
              </b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Dimenzije (Širina x Visina x Dužina): <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>
                {data?.data.sirina} x {data?.data.visina} x {data?.data.duzina}{" "}
                {data?.data.merna_jedinica_dimenzija}
              </b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv skladišta: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.naziv_skladista}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv pogona: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.naziv_pogona}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv regiona <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.naziv_regiona}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Vreme kreiranja: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.datum_kreiranja}</b>
            )}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LokacijaInfoPage;
