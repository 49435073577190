import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getKorisnici,
  getPogoni,
  getRadniCentri,
} from "../../../apis/administrator/getApi";
import { createKorisnik } from "../../../apis/administrator/postApi";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import {
  Alert,
  Autocomplete,
  Checkbox,
  createFilterOptions,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tableLang } from "../../../language/tableLang";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Avatar from "@mui/material/Avatar";

const steps = [
  {
    label: "Osnovni podaci o korisniku",
  },
  {
    label: "Biranje radnih centara",
  },
  {
    label: "Pregled podataka",
  },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormaZaUnosKorisnika = () => {
  let userId = localStorage.getItem("user_id");
  const queryClient = useQueryClient();
  const [type, setType] = useState(null);
  const [message, setMessage] = useState(null);

  const [ime, setIme] = useState(null);
  const [prezime, setPrezime] = useState(null);
  const [tip, setTip] = useState(null);

  const [radniCentri, setRadniCentri] = useState([]); // u slucaju da biramo
  const [pogon, setPogon] = useState(null); // u slucaju da biramo poslovoju

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getRadniCentri"],
    queryFn: getRadniCentri,
  });

  const {
    data: pogoni,
    isLoading: loadingPogoni,
    isError: errorPogoni,
  } = useQuery({
    queryKey: ["getPogoni"],
    queryFn: getPogoni,
  });

  const [imeError, setImeError] = React.useState(false);
  const [imeErrorMessage, setImeErrorMessage] = React.useState("");

  const [prezimeError, setPrezimeError] = React.useState(false);
  const [prezimeErrorMessage, setPrezimeErrorMessage] = React.useState("");

  const [tipError, setTipError] = React.useState(false);
  const [tipErrorMessage, setTipErrorMessage] = React.useState("");

  const [radniCentriError, setRadniCentriError] = React.useState(false);
  const [radniCentriErrorMessage, setRadniCentriErrorMessage] =
    React.useState("");

  const [pogonError, setPogonError] = React.useState(false);
  const [pogonErrorMessage, setPogonErrorMessage] = React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (event) => {
    if (activeStep == 0) {
      if (ime == null || ime == "") {
        setImeError(true);
        setImeErrorMessage("Ime je obavezno polje!");
        return;
      }

      if (prezime == null || prezime == "") {
        setPrezimeError(true);
        setPrezimeErrorMessage("Prezime je obavezno polje!");
        return;
      }

      if (tip == null) {
        setTipError(true);
        setTipErrorMessage("Tip je obavezno polje!");
        return;
      }
    } else if (activeStep == 1) {
      if (tip == 2) {
        if (pogon == null) {
          setPogonError(true);
          setPogonErrorMessage("Morate izabrati pogon!");
          return;
        }
      } else if (tip == 3) {
        if (radniCentri.length == 0) {
          setRadniCentriError(true);
          setRadniCentriErrorMessage(
            "Morate izabrati najmanje jedan radni centar!",
          );
          return;
        }
      } else {
        // nista ne radi na administratoru
      }
    } else if (activeStep == 2) {
      handleSubmit(event);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setIme(null);
    setPrezime(null);
    setPogon(null);
    setRadniCentri([]);
    setTip(null);
    setActiveStep(0);
  };

  const mutation = useMutation({
    mutationFn: (formData) => createKorisnik(formData),
    onSuccess: (response) => {
      setType(1);
      setMessage(response.data.message);
      queryClient.invalidateQueries(["getKorisnici", userId]);
    },
    onError: (error) => {
      setType(2);
      setMessage(error.response.data.message);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("ime", ime);
    formData.append("prezime", prezime);
    formData.append("id_uloga", tip);
    if (tip == 2) {
      formData.append("id_pogon", pogon.id);
    }

    if (tip == 3) {
      formData.append("radni_centri", JSON.stringify(radniCentri));
    }

    mutation.mutateAsync(formData).catch((e) => {});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box sx={{ mt: 2 }}>
            {type == 1 ? (
              <Alert variant="filled" severity="success">
                {message}
              </Alert>
            ) : type == 2 ? (
              <Alert variant="filled" severity="error">
                {message}
              </Alert>
            ) : null}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Resetovanje forme</Button>
          </Box>
        </React.Fragment>
      ) : activeStep == 0 ? (
        <React.Fragment>
          <Box>
            <TextField
              margin="normal"
              fullWidth
              name="ime"
              id="ime"
              label="Ime korisnika"
              required
              variant="outlined"
              error={imeError}
              helperText={imeErrorMessage}
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              name="prezime"
              id="prezime"
              label="Prezime korisnika"
              required
              variant="outlined"
              error={prezimeError}
              helperText={prezimeErrorMessage}
              value={prezime}
              onChange={(e) => setPrezime(e.target.value)}
            />
            <FormControl error={tipError} margin="normal" fullWidth>
              <InputLabel id="id_uloga_label">Tip korisnika</InputLabel>
              <Select
                labelId="id_uloga_label"
                id="id_uloga"
                name="id_uloga"
                value={tip}
                label="Tip korisnika"
                onChange={(e) => setTip(e.target.value)}
              >
                <MenuItem value={1}>Administrator</MenuItem>
                <MenuItem value={2}>Poslovodja</MenuItem>
                <MenuItem value={3}>Radnik</MenuItem>
              </Select>
              <FormHelperText>{tipErrorMessage}</FormHelperText>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
            >
              Nazad
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={handleNext}
              variant="contained"
              disabled={ime == null || prezime == null || tip == null}
            >
              {activeStep === steps.length - 1 ? "Završite" : "Sledeće"}
            </Button>
          </Box>
        </React.Fragment>
      ) : activeStep == 1 ? (
        <React.Fragment>
          <Box>
            {tip == 1 ? (
              <Box sx={{ textAlign: "center", m: 4 }}>
                <Typography
                  sx={{ color: "#6495ED", fontWeight: "bold", fontSize: 20 }}
                >
                  Nadležan za sve pogone i regione.
                </Typography>
              </Box>
            ) : tip == 2 ? (
              <Autocomplete
                disablePortal
                id="id_radni_centar"
                options={loadingPogoni ? [] : pogoni?.data}
                loading={loadingPogoni}
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Izaberite pogon"
                    placeholder="Izaberite pogon"
                    helperText={pogonErrorMessage}
                    error={pogonError}
                  />
                )}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setPogon(newValue);
                  }
                }}
                value={pogon}
                getOptionLabel={(option) =>
                  "Pogon: " +
                  option.naziv_pogona +
                  " | Region" +
                  option.naziv_regiona
                }
              />
            ) : (
              <Autocomplete
                sx={{ mt: 2 }}
                multiple
                value={radniCentri}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ naziv: "Izaberite sve", all: true }, ...filtered];
                }}
                // onChange={(event, newValue) => { setValue(newValue); }} <<<--- OLD
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all))
                    return setRadniCentri(
                      radniCentri.length == data?.data.length ? [] : data?.data,
                    );

                  setRadniCentri(newValue);
                }}
                options={data?.data}
                disableCloseOnSelect
                getOptionLabel={(option) => option.naziv}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        option.all
                          ? radniCentri.length == data?.data.length || tip == 1
                          : selected
                      }
                    />
                    {option.naziv +
                      (option.naziv != "Izaberite sve"
                        ? " | Linija: " +
                          option.naziv_linije +
                          " | Pogon: " +
                          option.naziv_pogona +
                          " | Region" +
                          option.naziv_regiona
                        : "")}
                  </li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Izaberite radne centre"
                    placeholder="Izaberite"
                    helperText={radniCentriErrorMessage}
                    error={radniCentriError}
                  />
                )}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
            >
              Nazad
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button variant="contained" onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Završite" : "Sledeće"}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContet: "center",
              alignItems: "center",
              textAlign: "center",
              mt: 3,
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#6495ED",
                textAlign: "center",
                margin: "auto",
              }}
            >
              {tip == 1 ? "A" : tip == 2 ? "P" : "R"}
            </Avatar>
            <Typography
              sx={{ color: "#6495ED", fontWeight: "bold", fontSize: 16, mt: 2 }}
            >
              {ime}
            </Typography>
            <Typography
              sx={{ color: "#6495ED", fontWeight: "bold", fontSize: 16, mt: 2 }}
            >
              {prezime}
            </Typography>

            {tip == 2 ? (
              <Typography
                sx={{
                  color: "#6495ED",
                  fontWeight: "bold",
                  fontSize: 14,
                  mt: 2,
                }}
              >
                {pogon.naziv_pogona + " | Region: " + pogon.naziv_regiona}
              </Typography>
            ) : null}

            {tip == 3
              ? radniCentri.map((item, index) => (
                  <Typography
                    sx={{
                      color: "#6495ED",
                      fontWeight: "bold",
                      fontSize: 14,
                      mt: 2,
                    }}
                  >
                    {item.naziv +
                      " | Linija: " +
                      item.naziv_linije +
                      " | Pogon: " +
                      item.naziv_pogona +
                      " | Region: " +
                      item.naziv_regiona}
                  </Typography>
                ))
              : null}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
            >
              Nazad
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button variant="contained" onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Završite" : "Sledeće"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

const KorisniciPage = () => {
  const navigate = useNavigate();
  let userId = localStorage.getItem("user_id");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getKorisnici"],
    queryFn: () => getKorisnici(userId),
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ime",
      headerName: "Ime korisnika",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prezime",
      headerName: "Prezime korisnika",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "barkod",
      headerName: "Barkod",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id_uloga",
      headerName: "Tip korisnika",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.id_uloga == 1) {
          return <span>Administrator</span>;
        } else if (params.row.id_uloga == 2) {
          return <span>Poslovodja</span>;
        } else {
          return <span>Radnik</span>;
        }
      },
    },
    {
      field: "informacije",
      headerName: "Informacije o korisniku",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ width: "100%", alignSelf: "center" }}
            endIcon={<PrivacyTipTwoToneIcon />}
            onClick={() =>
              navigate("../../administrator/korisnik/" + params.row.id)
            }
          >
            Informacije o korisniku
          </Button>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <AdminPanelSettingsTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o korisnicima
          </Typography>
          {open ? (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<RemoveTwoToneIcon />}
              onClick={handleOpen}
            >
              Sakrijte formu za dodavanje novog korisnika
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              onClick={handleOpen}
            >
              Dodajte novog korisnika
            </Button>
          )}
        </Paper>
      </Grid>
      <Grid style={open ? null : { display: "none" }} size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Forma za dodavanje novog korisnika
          </Typography>
          <FormaZaUnosKorisnika />
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          {isError ? (
            <Alert severity="error">
              Greška prilikom učitavanja korisnika!
            </Alert>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            <DataGrid
              localeText={tableLang}
              columns={columns}
              rows={isLoading ? [] : data?.data}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default KorisniciPage;
