import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getSkladiste } from "../../../apis/administrator/getApi";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Alert, CircularProgress, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tableLang } from "../../../language/tableLang";

const SkladisteInfoPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["skladisteInfo", id],
    queryFn: () => getSkladiste(id),
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv",
      headerName: "Naziv lokacije",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "barkod",
      headerName: "Barkod",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "xyz",
      headerName: "X; Y; Z koordinate",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_skladista",
      headerName: "Naziv skladišta",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_pogona",
      headerName: "Naziv pogona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_regiona",
      headerName: "Naziv regiona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "informacije",
      headerName: "Informacije o lokaciji",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ width: "100%", alignSelf: "center" }}
            endIcon={<PrivacyTipTwoToneIcon />}
            onClick={() =>
              navigate("../../administrator/lokacija/" + params.row.id)
            }
          >
            Informacije
          </Button>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <PrivacyTipTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o skladištu
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv skladišta: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.skladiste.naziv}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Tip skladišta: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>
                {data?.data.skladiste.tip_skladista}
              </b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv pogona: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>
                {data?.data.skladiste.naziv_pogona}
              </b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv regiona: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>
                {data?.data.skladiste.naziv_regiona}
              </b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Vreme kreiranja: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>
                {data?.data.skladiste.datum_kreiranja}
              </b>
            )}
          </Typography>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center", mb: 2 }}>
            Lokacije u skladištu:
          </Typography>
          {isError ? (
            <Alert severity="error">
              Greška prilikom učitavanja radnih centara!
            </Alert>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            <DataGrid
              localeText={tableLang}
              columns={columns}
              rows={isLoading ? [] : data?.data.lokacije}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SkladisteInfoPage;
