import axios from "axios";
import { url } from "../urls";

export const getRegioni = async () => {
  return axios.get(url + "/regioni", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getRegion = async (id) => {
  return axios.get(url + "/region/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getPogoni = async () => {
  return axios.get(url + "/pogoni", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getPogon = async (id) => {
  return axios.get(url + "/pogon/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getLinije = async () => {
  return axios.get(url + "/linije", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getLinija = async (id) => {
  return axios.get(url + "/linija/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getRadniCentriLinija = async (id) => {
  return axios.get(url + "/radni-centri-linija/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getRadniCentri = async () => {
  return axios.get(url + "/radni-centri", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const getRadniCentar = async (id) => {
  return axios.get(url + "/radni-centar/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getTipoviRadnogCentar = async () => {
  return axios.get(url + "/tipovi-radnog-centra", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getTipoviSkladista = async () => {
  return axios.get(url + "/tipovi-skladista", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getKorisnici = async (id) => {
  return axios.get(url + "/korisnici/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getKorisnik = async (id) => {
  return axios.get(url + "/korisnik/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getSkladista = async () => {
  return axios.get(url + "/skladista", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getSkladiste = async (id) => {
  return axios.get(url + "/skladiste/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getLokacije = async () => {
  return axios.get(url + "/lokacije", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getLokacija = async (id) => {
  return axios.get(url + "/lokacija/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getTransportniMedijumi = async () => {
  return axios.get(url + "/transportni-medijumi", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getTransportniMedijum = async (id) => {
  return axios.get(url + "/transportni-medijum/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getMasine = async () => {
  return axios.get(url + "/masine", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getMasina = async (id) => {
  return axios.get(url + "/masina/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getRepromaterijali = async () => {
  return axios.get(url + "/repromaterijali", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const getRepromaterijal = async (id) => {
  return axios.get(url + "/repromaterijal/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
