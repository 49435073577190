import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { srRS } from "@mui/material/locale";
import LoginIcon from "@mui/icons-material/Login";
import ThunderstormTwoToneIcon from "@mui/icons-material/ThunderstormTwoTone";
import Copyright from "../components/Copyright";
import { useMutation } from "@tanstack/react-query";
import { login } from "../apis/authApi";
import Notification from "../components/Notification";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: "10vh",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const theme = createTheme(
  {
    palette: {
      mode: "dark",
    },
  },
  srRS,
);

export default function LoginPage(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (formData) => login(formData),
    onSuccess: (response) => {
      setOpen(true);
      setType(1);
      setMessage(response.data.message);
      setLoading(false);
      localStorage.setItem("role_id", response.data.korisnik.id_uloga);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_id", response.data.korisnik.id);
      localStorage.setItem("index", 0);

      if (response.data.korisnik.id_uloga == 1) {
        setTimeout(() => {
          navigate("/administrator/pocetna");
        }, 2000);
      }
    },
    onError: (error) => {
      setOpen(true);
      setType(2);
      setMessage(error.response.data.message);
      setLoading(false);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    if (data.get("barkod") == "") {
      setLoading(false);
      setEmailErrorMessage("Molimo vas unesite validan barkod!");
      setEmailError(true);
      return;
    }
    setEmailErrorMessage(null);
    setEmailError(false);

    mutation.mutateAsync(data).catch((e) => {});
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Notification
        type={type}
        message={message}
        open={open}
        setOpen={setOpen}
      />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography component="h3" variant="h5" sx={{ width: "100%" }}>
            <ThunderstormTwoToneIcon /> KaproMES
          </Typography>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            Prijavljivanje <LoginIcon />
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="barkod"
                name="barkod"
                placeholder="1234567890"
                label="Barkod"
                autoFocus={true}
                required
                fullWidth
                variant="outlined"
                color={emailError ? "error" : "primary"}
                sx={{ ariaLabel: "email" }}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              endIcon={loading ? null : <LoginIcon />}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Prijavite se"}
            </Button>
          </Box>
        </Card>
      </SignInContainer>
      <Copyright />
    </ThemeProvider>
  );
}
