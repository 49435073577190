import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLokacije, getSkladista } from "../../../apis/administrator/getApi";
import { createLokacija } from "../../../apis/administrator/postApi";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Autocomplete,
  CircularProgress,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tableLang } from "../../../language/tableLang";
import FormControl from "@mui/material/FormControl";

function valuetext(value) {
  return `${value}°C`;
}

const LokacijePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const handleOpenAutocomplete = () => setOpenAutocomplete(true);
  const handleCloseAutocomplete = () => setOpenAutocomplete(false);

  const [skladisteError, setSkladisteError] = React.useState(false);
  const [skladisteErrorMessage, setSkladisteErrorMessage] = React.useState("");

  const [nazivError, setNazivError] = React.useState(false);
  const [nazivErrorMessage, setNazivErrorMessage] = React.useState("");

  const [barkodError, setBarkodError] = React.useState(false);
  const [barkodErrorMessage, setBarkodErrorMessage] = React.useState("");

  const [nosivostError, setNosivostError] = React.useState(false);
  const [nosivostErrorMessage, setNosivostErrorMessage] = React.useState("");

  const [visinaError, setVisinaError] = React.useState(false);
  const [visinaErrorMessage, setVisinaErrorMessage] = React.useState("");

  const [duzinaError, setDuzinaError] = React.useState(false);
  const [duzinaErrorMessage, setDuzinaErrorMessage] = React.useState("");

  const [sirinaError, setSirinaError] = React.useState(false);
  const [sirinaErrorMessage, setSirinaErrorMessage] = React.useState("");

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const [skladiste, setSkladiste] = useState(null);
  const [nosivostMera, setNosivostMera] = useState("kg");
  const [dimenzijeMera, setDimenzijeMera] = useState("m");
  const [fiksnaLokacija, setFiksnaLokacija] = useState(0);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getLokacije"],
    queryFn: getLokacije,
  });

  const {
    data: skladista,
    isLoading: loadingSkladista,
    isError: errorSkladista,
  } = useQuery({
    queryKey: ["getSkladista"],
    queryFn: getSkladista,
    enabled: openAutocomplete,
  });

  const mutation = useMutation({
    mutationFn: (formData) => createLokacija(formData),
    onSuccess: (response) => {
      setOpen(false);
      setOpenN(true);
      setType(1);
      setMessage(response.data.message);
      queryClient.invalidateQueries(["getLokacije"]);
    },
    onError: (error) => {
      setOpenN(true);
      setType(2);
      setMessage(error.response.data.message);
    },
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv",
      headerName: "Naziv lokacije",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "barkod",
      headerName: "Barkod",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "xyz",
      headerName: "X; Y; Z koordinate",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_skladista",
      headerName: "Naziv skladišta",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_pogona",
      headerName: "Naziv pogona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_regiona",
      headerName: "Naziv regiona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "informacije",
      headerName: "Informacije o lokaciji",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ width: "100%", alignSelf: "center" }}
            endIcon={<PrivacyTipTwoToneIcon />}
            onClick={() =>
              navigate("../../administrator/lokacija/" + params.row.id)
            }
          >
            Informacije
          </Button>
        );
      },
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (skladiste == null) {
      setSkladisteError(true);
      setSkladisteErrorMessage("Morate izabrati skladište!");
      return;
    }
    setSkladisteError(false);
    setSkladisteErrorMessage("");

    if (formData.get("naziv") == "") {
      setNazivError(true);
      setNazivErrorMessage("Naziv je obavezno polje!");
      return;
    }
    setNazivError(false);
    setNazivErrorMessage("");

    if (formData.get("nosivost") <= 0) {
      setNosivostError(true);
      setNosivostErrorMessage("Nosivost mora biti veća od nule!");
      return;
    }
    setNosivostError(false);
    setNosivostErrorMessage("");

    if (formData.get("sirina") <= 0) {
      setSirinaError(true);
      setSirinaErrorMessage("Širina mora biti veća od nule!");
      return;
    }
    setSirinaError(false);
    setSirinaErrorMessage("");

    if (formData.get("visina") <= 0) {
      setVisinaError(true);
      setVisinaErrorMessage("Visina mora biti veća od nule!");
      return;
    }
    setVisinaError(false);
    setVisinaErrorMessage("");

    if (formData.get("duzina") <= 0) {
      setDuzinaError(true);
      setDuzinaErrorMessage("Dužina mora biti veća od nule!");
      return;
    }
    setDuzinaError(false);
    setDuzinaErrorMessage("");

    formData.append("id_skladiste", skladiste.id);
    formData.append("merna_jedinica_nosivost", nosivostMera);
    formData.append("merna_jedinica_dimenzija", dimenzijeMera);
    formData.append("fiksna_lokacija", fiksnaLokacija);
    formData.append("proizvod", null);

    setOpenN(false);
    setType(null);
    setMessage(null);

    mutation.mutateAsync(formData).catch((e) => {});
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <AdminPanelSettingsTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o lokacijama
          </Typography>
          {open ? (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<RemoveTwoToneIcon />}
              onClick={handleOpen}
            >
              Sakrijte formu za dodavanje nove lokacije
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              onClick={handleOpen}
            >
              Dodajte novu lokaciju
            </Button>
          )}
        </Paper>
      </Grid>
      <Grid style={open ? null : { display: "none" }} size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Forma za dodavanje nove lokacije
          </Typography>
          <Box
            sx={{ m: 2 }}
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <Autocomplete
              sx={{ width: "100%", mt: 2 }}
              open={openAutocomplete}
              onOpen={handleOpenAutocomplete}
              onClose={handleCloseAutocomplete}
              isOptionEqualToValue={(option, value) =>
                option.naziv == value.naziv
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSkladiste(newValue);
                }
              }}
              value={skladiste}
              getOptionLabel={(option) => option.naziv}
              options={loadingSkladista ? [] : skladista?.data}
              loading={loadingSkladista}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Izaberite skladište"
                  error={skladisteError}
                  helperText={skladisteErrorMessage}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSkladista ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    },
                  }}
                />
              )}
            />
            <TextField
              fullWidth
              name="naziv"
              id="naziv"
              label="Naziv lokacije"
              required
              variant="outlined"
              error={nazivError}
              helperText={nazivErrorMessage}
              margin="normal"
            />

            <TextField
              fullWidth
              name="barkod"
              id="barkod"
              label="Barkod"
              variant="outlined"
              error={barkodError}
              helperText={
                barkodErrorMessage != ""
                  ? barkodErrorMessage
                  : "Opcionalno polje (ako se ne unese, barkod se pravi sam)"
              }
              margin="normal"
            />
            <Typography id="track-false-slider" gutterBottom>
              X koordinata
            </Typography>
            <Slider
              id="x"
              name="x"
              aria-label="X"
              defaultValue={0}
              getAriaValueText={valuetext}
              shiftStep={1}
              step={1}
              marks
              min={0}
              max={100}
              valueLabelDisplay="on"
            />

            <Typography id="track-false-slider" gutterBottom>
              Y koordinata
            </Typography>
            <Slider
              id="y"
              name="y"
              aria-label="Y"
              defaultValue={0}
              getAriaValueText={valuetext}
              shiftStep={1}
              step={1}
              marks
              min={0}
              max={100}
              valueLabelDisplay="on"
            />

            <Typography id="track-false-slider" gutterBottom>
              Z koordinata
            </Typography>
            <Slider
              id="z"
              name="z"
              aria-label="Z"
              defaultValue={0}
              getAriaValueText={valuetext}
              shiftStep={1}
              step={1}
              marks
              min={0}
              max={100}
              valueLabelDisplay="on"
            />

            <TextField
              fullWidth
              name="nosivost"
              id="nosivost"
              label="Nosivost"
              variant="outlined"
              required
              error={nosivostError}
              helperText={nosivostErrorMessage}
              margin="normal"
              slotProps={{
                input: {
                  endAdornment: (
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel id="demo-simple-select-label">
                        Jedinica
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={nosivostMera}
                        label="Jedinica"
                        onChange={(e) => setNosivostMera(e.target.value)}
                      >
                        <MenuItem value="t">t</MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="g">g</MenuItem>
                        <MenuItem value="mg">mg</MenuItem>
                      </Select>
                    </FormControl>
                  ),
                },
              }}
            />

            <TextField
              fullWidth
              name="sirina"
              id="sirina"
              label="Širina"
              variant="outlined"
              required
              error={sirinaError}
              helperText={sirinaErrorMessage}
              margin="normal"
              slotProps={{
                input: {
                  endAdornment: (
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel id="demo-simple-select-label">
                        Jedinica
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dimenzijeMera}
                        label="Jedinica"
                        onChange={(e) => setDimenzijeMera(e.target.value)}
                      >
                        <MenuItem value="m">m</MenuItem>
                        <MenuItem value="dm">dm</MenuItem>
                        <MenuItem value="cm">cm</MenuItem>
                        <MenuItem value="mm">mm</MenuItem>
                      </Select>
                    </FormControl>
                  ),
                },
              }}
            />

            <TextField
              fullWidth
              name="visina"
              id="visina"
              label="Visina"
              variant="outlined"
              required
              error={visinaError}
              helperText={visinaErrorMessage}
              margin="normal"
              slotProps={{
                input: {
                  endAdornment: (
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel id="demo-simple-select-label">
                        Jedinica
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dimenzijeMera}
                        label="Jedinica"
                        onChange={(e) => setDimenzijeMera(e.target.value)}
                      >
                        <MenuItem value="m">m</MenuItem>
                        <MenuItem value="dm">dm</MenuItem>
                        <MenuItem value="cm">cm</MenuItem>
                        <MenuItem value="mm">mm</MenuItem>
                      </Select>
                    </FormControl>
                  ),
                },
              }}
            />

            <TextField
              fullWidth
              name="duzina"
              id="duzina"
              label="Dužina"
              variant="outlined"
              required
              error={duzinaError}
              helperText={duzinaErrorMessage}
              margin="normal"
              slotProps={{
                input: {
                  endAdornment: (
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel id="demo-simple-select-label">
                        Jedinica
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dimenzijeMera}
                        label="Jedinica"
                        onChange={(e) => setDimenzijeMera(e.target.value)}
                      >
                        <MenuItem value="m">m</MenuItem>
                        <MenuItem value="dm">dm</MenuItem>
                        <MenuItem value="cm">cm</MenuItem>
                        <MenuItem value="mm">mm</MenuItem>
                      </Select>
                    </FormControl>
                  ),
                },
              }}
            />

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Da li je ovo fiksna lokacija? (Lokacija koja sadrži samo jedan
                tip proizvoda)
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fiksnaLokacija}
                label="Da li je ovo fiksna lokacija? (Lokacija koja sadrži samo jedan
                tip proizvoda)"
                onChange={(e) => setFiksnaLokacija(e.target.value)}
              >
                <MenuItem value={0}>NE</MenuItem>
                <MenuItem value={1}>DA</MenuItem>
              </Select>
            </FormControl>
            {fiksnaLokacija == 1
              ? null // TREBA AUTOCOMPLETE ZA PROIZVODE
              : null}
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              type="submit"
            >
              Unesite novu lokaciju
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          {isError ? (
            <Alert severity="error">Greška prilikom učitavanja lokacija!</Alert>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            <DataGrid
              localeText={tableLang}
              columns={columns}
              rows={isLoading ? [] : data?.data}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LokacijePage;
