import React from "react";
import JsBarcode from "jsbarcode";
import logo from "../assets/tren.png";
import mes from "../assets/logo.png";

const BarkodLokacija = ({ naziv, barkod }) => {
  setTimeout(() => {
    JsBarcode("#barcode", barkod, {
      format: "CODE128",
      lineColor: "#000000",
      width: 2,
      height: 60,
      margin: 2,
      displayValue: true,
    });
  }, 200);
  return (
    <div>
      <div
        style={{
          width: "4in",
          height: "2in",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          margin: "auto",
          position: "relative",
        }}
      >
        <img
          src={mes}
          height={35}
          alt={"#"}
          style={{ position: "absolute", top: 10, left: 10 }}
        />
        <img
          src={logo}
          height={35}
          alt={"#"}
          style={{ position: "absolute", top: 10, right: 10 }}
        />
        <p
          style={{
            textAlign: "center",
            fontSize: 16,
            fontWeight: "bold",
            color: "black",
          }}
        >
          {naziv}
        </p>
        <svg id="barcode"></svg>
      </div>
    </div>
  );
};

export default BarkodLokacija;
