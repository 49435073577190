import * as React from "react";

import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import AppNavbar from "../components/administrator/AppNavbar";
import { srRS } from "@mui/material/locale";
import SideMenu from "../components/administrator/SideMenu";
import Copyright from "../components/Copyright";

const theme = createTheme(
  {
    palette: {
      mode: "dark",
    },
  },
  srRS,
);

const AdminLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Box sx={{ m: 4 }}>{children}</Box>
          <Box sx={{ m: 4 }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default AdminLayout;
