import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import { useQuery } from "@tanstack/react-query";
import { userInfo } from "../../apis/userApi";
import { CircularProgress } from "@mui/material";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

export default function SideMenu() {
  let id = localStorage.getItem("user_id");
  const { data, isLoading, isError } = useQuery({
    queryKey: ["userInfo", id],
    queryFn: () => userInfo(id),
  });

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <MenuContent />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Avatar sizes="small" alt="User Image" sx={{ width: 36, height: 36 }} />
        <Box sx={{ mr: "auto" }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, lineHeight: "16px" }}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              data?.data.korisnik.ime + " " + data?.data.korisnik.prezime
            )}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : data?.data.korisnik.id_uloga == 1 ? (
              "Administrator"
            ) : data?.data.korisnik.id_uloga == 2 ? (
              "Poslovodja"
            ) : (
              "Radnik"
            )}
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
