import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getRepromaterijali } from "../../../apis/administrator/getApi";
import { createRepromaterijal } from "../../../apis/administrator/postApi";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Alert, LinearProgress, ListItemAvatar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tableLang } from "../../../language/tableLang";
import List from "@mui/material/List";
import HelpCenterTwoToneIcon from "@mui/icons-material/HelpCenterTwoTone";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const RepromaterijaliPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  let opis = localStorage.getItem("opis")
    ? JSON.parse(localStorage.getItem("opis"))
    : [];

  const [kljuc, setKljuc] = useState(null);
  const [vrednost, setVrednost] = useState(null);

  const [nazivError, setNazivError] = React.useState(false);
  const [nazivErrorMessage, setNazivErrorMessage] = React.useState("");

  const [barkodError, setBarkodError] = React.useState(false);
  const [barkodErrorMessage, setBarkodErrorMessage] = React.useState("");

  const [kljucError, setKljucError] = React.useState(false);
  const [kljucErrorMessage, setKljucErrorMessage] = React.useState("");

  const [vrednostError, setVrednostError] = React.useState(false);
  const [vrednostErrorMessage, setVrednostErrorMessage] = React.useState("");

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getRepromaterijali"],
    queryFn: getRepromaterijali,
  });

  const mutation = useMutation({
    mutationFn: (formData) => createRepromaterijal(formData),
    onSuccess: (response) => {
      setOpen(false);
      setOpenN(true);
      setType(1);
      setMessage(response.data.message);
      localStorage.removeItem("opis");
      queryClient.invalidateQueries(["getRepromaterijali"]);
    },
    onError: (error) => {
      setOpenN(true);
      setType(2);
      setMessage(error.response.data.message);
    },
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv",
      headerName: "Naziv repromaterijala",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "barkod",
      headerName: "Barkod repromaterijala",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "informacije",
      headerName: "Informacije",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ width: "100%", alignSelf: "center" }}
            endIcon={<PrivacyTipTwoToneIcon />}
            onClick={() =>
              navigate("../../administrator/repromaterijal/" + params.row.id)
            }
          >
            Informacije
          </Button>
        );
      },
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (formData.get("naziv") == "") {
      setNazivError(true);
      setNazivErrorMessage("Naziv je obavezno polje!");
      return;
    }

    setNazivError(false);
    setNazivErrorMessage("");

    if (formData.get("barkod") == "") {
      setBarkodError(true);
      setBarkodErrorMessage("Barkod je obavezno polje!");
      return;
    }

    setBarkodError(false);
    setBarkodErrorMessage("");

    formData.append("opis", JSON.stringify(opis));

    mutation.mutateAsync(formData).catch((e) => {});
  };

  const handleAddOpis = (event) => {
    event.preventDefault();

    if (kljuc == null || kljuc == "") {
      setKljucError(true);
      setKljucErrorMessage("Naziv vrednosti je obavezno polje!");
      return;
    }

    if (vrednost == null || vrednost == "") {
      setVrednostError(true);
      setVrednostErrorMessage("Vrednost je obavezno polje!");
      return;
    }

    opis.forEach((item, index) => {
      if (item.kljuc == kljuc) {
        setType(2);
        setMessage("Ovaj naziv vrednosti već postoji!");
        setOpenN(true);
      }
    });

    if (
      opis.push({
        kljuc: kljuc,
        vrednost: vrednost,
      })
    ) {
      localStorage.setItem("opis", JSON.stringify(opis));
      setType(1);
      setMessage("Uspešno ste dodali novi opis repromaterijala!");
      setOpenN(true);
      setKljuc(null);
      setVrednost(null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <AdminPanelSettingsTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o repromaterijalima
          </Typography>
          {open ? (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<RemoveTwoToneIcon />}
              onClick={handleOpen}
            >
              Sakrijte formu za dodavanje novog repromaterijala
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              onClick={handleOpen}
            >
              Dodajte novi repromaterijal
            </Button>
          )}
        </Paper>
      </Grid>
      <Grid style={open ? null : { display: "none" }} size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Forma za dodavanje repromaterijala
          </Typography>
          <Box
            sx={{ m: 2 }}
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <TextField
              margin="normal"
              fullWidth
              name="naziv"
              id="naziv"
              label="Naziv repromaterijala"
              required
              variant="outlined"
              error={nazivError}
              helperText={nazivErrorMessage}
            />
            <TextField
              margin="normal"
              fullWidth
              name="barkod"
              id="barkod"
              label="Barkod repromaterijala"
              required
              variant="outlined"
              error={barkodError}
              helperText={barkodErrorMessage}
            />
            <Grid container spacing={2}>
              <Grid size={{ xl: 4, lg: 4, sm: 6, xs: 12 }}>
                <TextField
                  value={kljuc}
                  onChange={(e) => setKljuc(e.target.value)}
                  margin="normal"
                  fullWidth
                  label="Naziv vrednosti"
                  required
                  variant="outlined"
                  error={kljucError}
                  helperText={kljucErrorMessage}
                />
              </Grid>
              <Grid size={{ xl: 4, lg: 4, sm: 6, xs: 12 }}>
                <TextField
                  value={vrednost}
                  onChange={(e) => setVrednost(e.target.value)}
                  margin="normal"
                  fullWidth
                  label="Vrednost"
                  required
                  variant="outlined"
                  error={vrednostError}
                  helperText={vrednostErrorMessage}
                />
              </Grid>
              <Grid size={{ xl: 4, lg: 4, sm: 12, xs: 12 }}>
                <Button
                  marign="normal"
                  variant="contained"
                  size="large"
                  sx={{ width: "100%", alignSelf: "center", mt: 2 }}
                  endIcon={<AddTwoToneIcon />}
                  onClick={handleAddOpis}
                >
                  Dodajte novi opis
                </Button>
              </Grid>
            </Grid>
            <List sx={{ width: "100%" }}>
              {opis.length == 0 ? (
                <Alert
                  severity="info"
                  sx={{ width: "100%", justifyContent: "center" }}
                >
                  Ne postoje elementi u opisu!
                </Alert>
              ) : (
                opis.map((item, index) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <HelpCenterTwoToneIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.kljuc}
                      secondary={item.vrednost}
                    />
                  </ListItem>
                ))
              )}
            </List>
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              type="submit"
            >
              Unesite novi repromaterijal
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          {isError ? (
            <Alert severity="error">
              Greška prilikom učitavanja repromaterijala!
            </Alert>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            <DataGrid
              localeText={tableLang}
              columns={columns}
              rows={isLoading ? [] : data?.data}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RepromaterijaliPage;
