import React from "react";
import { Outlet, useNavigate } from "react-router";
import AdminLayout from "../layouts/AdminLayout";

const AdminRoutes = () => {
  const navigate = useNavigate();
  let role_id = localStorage.getItem("role_id");

  if (role_id != 1) {
    navigate(-1);
    return;
  }

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default AdminRoutes;
