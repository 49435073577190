import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getRegioni } from "../../../apis/administrator/getApi";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import { DataGrid } from "@mui/x-data-grid";
import { tableLang } from "../../../language/tableLang";
import Button from "@mui/material/Button";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Notification from "../../../components/Notification";
import { createRegion } from "../../../apis/administrator/postApi";
import { useNavigate } from "react-router";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import { Alert, LinearProgress } from "@mui/material";

const RegioniPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const [nazivError, setNazivError] = React.useState(false);
  const [nazivErrorMessage, setNazivErrorMessage] = React.useState("");

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getRegioni"],
    queryFn: getRegioni,
  });

  const mutation = useMutation({
    mutationFn: (formData) => createRegion(formData),
    onSuccess: (response) => {
      setOpen(false);
      setOpenN(true);
      setType(1);
      setMessage(response.data.message);
      queryClient.invalidateQueries(["getRegioni"]);
    },
    onError: (error) => {
      setOpenN(true);
      setType(2);
      setMessage(error.response.data.message);
    },
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv",
      headerName: "Naziv regiona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "informacije",
      headerName: "Informacije o regionu",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ width: "100%", alignSelf: "center" }}
            endIcon={<PrivacyTipTwoToneIcon />}
            onClick={() =>
              navigate("../../administrator/region/" + params.row.id)
            }
          >
            Informacije o regionu
          </Button>
        );
      },
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (formData.get("naziv") == "") {
      setNazivError(true);
      setNazivErrorMessage("Naziv je obavezno polje!");
      return;
    }

    setNazivError(false);
    setNazivErrorMessage("");

    mutation.mutateAsync(formData).catch((e) => {});
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <AdminPanelSettingsTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o regionima
          </Typography>
          {open ? (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<RemoveTwoToneIcon />}
              onClick={handleOpen}
            >
              Sakrijte formu za dodavanje novog regiona
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              onClick={handleOpen}
            >
              Dodajte novi region
            </Button>
          )}
        </Paper>
      </Grid>
      <Grid style={open ? null : { display: "none" }} size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Forma za dodavanje regiona
          </Typography>
          <Box
            sx={{ m: 2 }}
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              name="naziv"
              id="naziv"
              label="Naziv regiona"
              required
              variant="outlined"
              error={nazivError}
              helperText={nazivErrorMessage}
            />
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              type="submit"
            >
              Unesite novi region
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          {isError ? (
            <Alert severity="error">Greška prilikom učitavanja regiona!</Alert>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            <DataGrid
              localeText={tableLang}
              columns={columns}
              rows={isLoading ? [] : data?.data}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RegioniPage;
