import axios from "axios";
import { url } from "./urls";

export const userInfo = async (id) => {
  return axios.get(url + `/user-info/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
