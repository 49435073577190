import React, { useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getRegion } from "../../../apis/administrator/getApi";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import { CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";

const RegionInfoPage = () => {
  let { id } = useParams();

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["regionInfo", id],
    queryFn: () => getRegion(id),
  });

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <PrivacyTipTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o regionu
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv regiona: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.naziv}</b>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Vreme kreiranja: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.datum_kreiranja}</b>
            )}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RegionInfoPage;
