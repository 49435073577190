import axios from "axios";
import { url } from "./urls";

export const login = async (formData) => {
  const response = axios.post(url + "/login", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response;
};

export const logout = async (formData) => {
  const response = axios.post(url + "/logout", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  return response;
};
