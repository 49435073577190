import React, { useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getRepromaterijal } from "../../../apis/administrator/getApi";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Divider from "@mui/material/Divider";
import { Alert, CircularProgress, ListItemAvatar } from "@mui/material";
import Button from "@mui/material/Button";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import BarkodRepromaterijal from "../../../components/BarkodRepromaterijal";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import HelpCenterTwoToneIcon from "@mui/icons-material/HelpCenterTwoTone";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

const RepromaterijalInfoPage = () => {
  let { id } = useParams();

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["repromaterijalInfo", id],
    queryFn: () => getRepromaterijal(id),
  });

  const printDiv = (divId) => {
    const container = document.getElementById(divId);
    const width = "100%";
    const height = "100%";
    const printWindow = window.open(
      "",
      "PrintMap",
      "width=" + width + ",height=" + height,
    );
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <PrivacyTipTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o repromaterijalu
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Naziv: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.naziv}</b>
            )}
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Barkod: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
                id="stampanje"
              >
                <BarkodRepromaterijal
                  naziv={data?.data.naziv}
                  barkod={data?.data.barkod}
                />
              </div>
            )}
            <Button
              sx={{ mt: 2, mb: 2 }}
              endIcon={<LocalPrintshopIcon />}
              variant="contained"
              onClick={() => printDiv("stampanje")}
            >
              Štampajte barkod
            </Button>
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Opis: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <List sx={{ width: "100%" }}>
                {JSON.parse(data?.data.opis).length == 0 ? (
                  <Alert
                    severity="info"
                    sx={{ width: "100%", justifyContent: "center" }}
                  >
                    Ne postoje elementi u opisu!
                  </Alert>
                ) : (
                  JSON.parse(data?.data.opis).map((item, index) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <HelpCenterTwoToneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.kljuc}
                        secondary={item.vrednost}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            )}
          </Typography>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Vreme kreiranja: <br />
            {isLoading ? (
              <CircularProgress size={22} />
            ) : (
              <b style={{ color: "#6495ED" }}>{data?.data.datum_kreiranja}</b>
            )}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RepromaterijalInfoPage;
