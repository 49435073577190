import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLinije, getPogoni } from "../../../apis/administrator/getApi";
import { createLinija } from "../../../apis/administrator/postApi";
import Button from "@mui/material/Button";
import PrivacyTipTwoToneIcon from "@mui/icons-material/PrivacyTipTwoTone";
import Grid from "@mui/material/Grid2";
import Notification from "../../../components/Notification";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Autocomplete,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tableLang } from "../../../language/tableLang";

const LinijePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const handleOpenAutocomplete = () => setOpenAutocomplete(true);
  const handleCloseAutocomplete = () => setOpenAutocomplete(false);

  const [nazivError, setNazivError] = React.useState(false);
  const [nazivErrorMessage, setNazivErrorMessage] = React.useState("");

  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [openN, setOpenN] = useState(false);

  const [pogon, setPogon] = useState(null);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getLinije"],
    queryFn: getLinije,
  });

  const {
    data: pogoni,
    isLoading: loadingPogoni,
    isError: errorPogoni,
  } = useQuery({
    queryKey: ["getPogoni"],
    queryFn: getPogoni,
    enabled: openAutocomplete,
  });

  const mutation = useMutation({
    mutationFn: (formData) => createLinija(formData),
    onSuccess: (response) => {
      setOpen(false);
      setOpenN(true);
      setType(1);
      setMessage(response.data.message);
      queryClient.invalidateQueries(["getLinije"]);
    },
    onError: (error) => {
      setOpenN(true);
      setType(2);
      setMessage(error.response.data.message);
    },
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_linije",
      headerName: "Naziv linije",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_pogona",
      headerName: "Naziv pogona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "naziv_regiona",
      headerName: "Naziv regiona",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "informacije",
      headerName: "Informacije o liniji",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ width: "100%", alignSelf: "center" }}
            endIcon={<PrivacyTipTwoToneIcon />}
            onClick={() =>
              navigate("../../administrator/linija/" + params.row.id)
            }
          >
            Informacije o liniji
          </Button>
        );
      },
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (formData.get("naziv") == "") {
      setNazivError(true);
      setNazivErrorMessage("Naziv je obavezno polje!");
      return;
    }

    if (pogon == null) {
      setOpenN(true);
      setType(2);
      setMessage("Morate izabrati pogon!");
      return;
    }

    setNazivError(false);
    setNazivErrorMessage("");

    setOpenN(false);
    setType(null);
    setMessage(null);

    formData.append("id_pogon", pogon.id);

    mutation.mutateAsync(formData).catch((e) => {});
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Notification
          type={type}
          message={message}
          open={openN}
          setOpen={setOpenN}
        />
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            <AdminPanelSettingsTwoToneIcon size={22} sx={{ mr: 2 }} />
            Informacije o linijama
          </Typography>
          {open ? (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<RemoveTwoToneIcon />}
              onClick={handleOpen}
            >
              Sakrijte formu za dodavanje nove linije
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              onClick={handleOpen}
            >
              Dodajte novu liniju
            </Button>
          )}
        </Paper>
      </Grid>
      <Grid style={open ? null : { display: "none" }} size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Forma za dodavanje nove linije
          </Typography>
          <Box
            sx={{ m: 2 }}
            component="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              name="naziv"
              id="naziv"
              label="Naziv linije"
              required
              variant="outlined"
              error={nazivError}
              helperText={nazivErrorMessage}
            />
            <Autocomplete
              sx={{ width: "100%", mt: 2 }}
              open={openAutocomplete}
              onOpen={handleOpenAutocomplete}
              onClose={handleCloseAutocomplete}
              isOptionEqualToValue={(option, value) =>
                option.naziv_pogona == value.naziv_regiona
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setPogon(newValue);
                }
              }}
              getOptionLabel={(option) =>
                option.naziv_pogona + " | Region: " + option.naziv_regiona
              }
              options={loadingPogoni ? [] : pogoni?.data}
              loading={loadingPogoni}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Izaberite pogon"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingPogoni ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    },
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              sx={{ width: "100%", alignSelf: "center", mt: 2 }}
              endIcon={<AddTwoToneIcon />}
              type="submit"
            >
              Unesite novu liniju
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper
          sx={{
            p: 2,
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          {isError ? (
            <Alert severity="error">Greška prilikom učitavanja linija!</Alert>
          ) : isLoading ? (
            <LinearProgress />
          ) : (
            <DataGrid
              localeText={tableLang}
              columns={columns}
              rows={isLoading ? [] : data?.data}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LinijePage;
