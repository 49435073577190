import axios from "axios";
import { url } from "../urls";

export const createRegion = async (formData) => {
  return axios.post(url + "/create-region", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const editRegion = async (formData) => {
  return axios.post(url + "/edit-region", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createPogon = async (formData) => {
  return axios.post(url + "/create-pogon", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createLinija = async (formData) => {
  return axios.post(url + "/create-linija", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createRadniCentar = async (formData) => {
  return axios.post(url + "/create-radni-centar", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createTipRadnogCentra = async (formData) => {
  return axios.post(url + "/create-tip-radnog-centra", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createTipSkladista = async (formData) => {
  return axios.post(url + "/create-tip-skladista", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createKorisnik = async (formData) => {
  return axios.post(url + "/create-korisnik", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createSkladiste = async (formData) => {
  return axios.post(url + "/create-skladiste", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createLokacija = async (formData) => {
  return axios.post(url + "/create-lokacija", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createTransportniMedijum = async (formData) => {
  return axios.post(url + "/create-transportni-medijum", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createMasina = async (formData) => {
  return axios.post(url + "/create-masina", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const createRepromaterijal = async (formData) => {
  return axios.post(url + "/create-repromaterijal", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
